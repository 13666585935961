@import './normalize.css';

@font-face {
  font-family: 'NeuzeitSLTStd-Book';
  src: url('/NeuzeitSLTStd-Book.otf') format('opentype');
}

* {
  box-sizing: border-box;
  -webkit-scrollbar {
    width: 0 !important;
  }
}

body,
html {
  background-color: black;
}

button {
  outline: none;
  border: none;
}

button:hover {
  cursor: pointer;
}

a {
  color: white;
}

/* p {
  font-family: 'NeuzeitSLTStd-Book';
} */

li,
ol,
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

::-webkit-scrollbar {
  display: none;
}

.clip {
  clip-path: inset(0px 0px);
}

#VideoBackground {
  position: sticky;
  width: 100vw;
  height: 100vh;
  background-color: black;
  /* z-index: -1; */
  top: 0;
  transition: 200ms transform;
}

#PhotoSection {
  margin-top: 360px;
  position: relative;
  transition: 200ms transform;
}

#PhotoBackground {
  position: sticky;
  width: 100vw;
  height: 100vh;
  /* z-index: -1; */
  top: 0;
  display: grid;
  grid-template-columns: repeat(9, [col-start] 1fr);
  column-gap: 25px;
}

#InnerPhotoBackground {
  grid-column-start: 1;
  grid-column-end: 10;
  background-color: black;
  height: 100vh;
  max-height: 100vh;
  overflow-y: hidden;
}

@media only screen and (min-width: 768px) {
  #InnerPhotoBackground {
    grid-column-start: 1;
    grid-column-end: 7;
  }
}

#PhotoColumnContainer {
  width: 100%;
  display: flex;
  transition: transform 250ms;
}

.PhotoColumn {
  width: 50%;
  display: flex;
  flex-direction: column;
  background-color: black;
}

.PhotoItem {
  margin-bottom: 24px;
  position: 'relative';
}

.PhotoItemLeft {
  margin-right: 12px;
}

.PhotoItemRight {
  margin-left: 12px;
}

.PhotoImage {
  position: relative;
  transition: 400ms opacity;
  width: 100%;
  height: auto;
}

.StickyList {
  padding-bottom: 120px;
  /* mix-blend-mode: lighten; */
  padding-right: 50px;
  padding-left: 50px;
  display: grid;
  grid-template-columns: repeat(9, [col-start] 1fr);
  column-gap: 25px;
  /* overflow: hidden; */
}

.InnerStickyList {
  /* background-blend-mode: lighten; */
  /* background-color: black; */
  /* z-index: 3; */
  grid-column-start: 1;
  grid-column-end: 10;
}

@media only screen and (min-width: 768px) {
  .InnerStickyList {
    grid-column-start: 6;
    grid-column-end: 10;
  }
}

.StickyListSubHeading {
  padding-top: 120px;
  padding-bottom: 18px;
  background-color: transparent;
  top: 0;
  /* position: sticky; */
  color: white;
  /* border-bottom: 1px solid white; */
  /* z-index: 4; */
  font-family: 'Libre Franklin', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.02em;
  line-height: 27px;
  mix-blend-mode: exclusion;
}

.ProjectListItem {
  width: 100%;
  transition: opacity 100ms;
  color: white;
}

.ProjectListItemInner {
  color: white;
  /* background-color: transparent; */
  /* text-shadow: 1px 1px 2px black;  */
  display: flex;
  align-items: baseline;
  flex-direction: column;
  flex-wrap: wrap;
  /* background-color: black; */
  padding-top: 15px;
  padding-bottom: 15px;
  /* mix-blend-mode: exclusion; */
}

@media only screen and (min-width: 768px) {
  .ProjectListItemInner {
    flex-direction: row;
  }
}

.flex {
  display: flex;
  align-items: center;
}

.ProjectTitle {
  margin-left: 6px;
  font-family: 'Libre Franklin', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: white;
}

.ProjectClient {
  margin-right: 6px;
  font-family: 'Libre Franklin', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: white;
}

#VideoBackgroundInner {
  position: relative;
  height: 100%;
  width: 100%;
}

.Video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 500ms, z-index 0ms;
  display: block;
  position: absolute;
}

#VideoOverlay {
  background-color: #ff3d00;
  /* mix-blend-mode: multiply; */
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 50;
  transition: opacity 300ms;
}

.Rule {
  height: 1px;
  background-color: white;
  content: ' ';
}

.DropdownButton {
  display: flex;
  align-items: center;
  padding: 0px;
  background-color: transparent;
  border: none;
  font-family: 'Libre Franklin', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  color: white;
  transition: 200ms transform, 200ms opacity;
  position: absolute;
  top: 0px;
  height: 75px;
}

.Arrow {
  position: absolute;
  z-index: 150;
  right: 120px;
  top: 45px;
  height: 10px;
  width: 10px;
}

/* .about-left, .about-right{
  width: 45vw;
  padding-bottom: 80px;
  background-color: black;
}

.about-right{

} */

.aboutItemTitle {
  margin-top: 48px;
  margin-bottom: 0px;
  font-weight: 400;
  text-transform: uppercase;
}

.aboutItemBody {
  display: flex;
  min-width: 50px;
}

.about .content {
  flex: 1;
}

.clientListItem {
  display: inline-block;
  margin-right: 0.4em;
  color: white;
}

.clientList {
  color: white;
}

.smallDash {
  min-width: 40px;
  max-width: 40px;
  height: 1px;
  background-color: white;
  margin-top: 24px;
  flex: 0;
  margin-right: 10px;
}

.aboutItemBody {
  display: flex;
  min-width: 50px;
}

#About {
  z-index: 600;
  position: fixed;
  top: 0;
  left: 0;
  transition: transform 300ms;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100vw;
  min-width: 100vw;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: black;
}

#AboutLayout {
  display: grid;
  grid-row: auto;
  grid-template-columns: repeat(9, [col-start] 1fr);
  column-gap: 25px;
}

#AboutBody {
  padding-top: 125px;
  grid-column-start: 1;
  grid-column-end: 10;
  grid-row-start: 2;
  grid-row-end: 3;
  padding-left: 50px;
  padding-right: 25px;
  color: white;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: Libre Franklin, Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.02em;
  padding-bottom: 125px;
  height: auto;
}

#AboutImage {
  content: '';
  grid-column-start: 1;
  grid-column-end: 10;
  grid-row-start: 1;
  grid-row-end: 2;
  height: 80vh;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
}

@media only screen and (min-width: 768px) {
  #About {
    overflow-y: hidden;
    height: 100vh;
  }

  #AboutImage {
    grid-column-start: 6;
    grid-column-end: 10;
    grid-row-start: 1;
    grid-row-end: 2;
    height: 100vh;
  }

  #AboutBody {
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 2;
    height: 100vh;
    overflow-y: scroll;
  }
}

#MenuControl {
  position: fixed;
  top: 50px;
  left: 50px;
  right: auto;
}

@media only screen and (min-width: 768px) {
  #MenuControl {
    top: 0px;
    right: 50px;
    left: auto;
  }
}

#AboutControl {
  position: fixed;
  top: 50px;
  right: 50px;
}

@media only screen and (min-width: 768px) {
  #AboutControl {
    top: 0px;
    right: 50px;
  }
}

#GalleryButtonBox {
  /* top: 85%; */
  mix-blend-mode: exclusion;
  top: 50%;
  width: 100vw;
  transform: translateY(-50%);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (min-width: 768px) {
  #GalleryButtonBox {
    top: 50%;
    transform: translateY(-50%);
  }
}

.GalleryButton {
  height: 60px;
  width: 60px;
}

.LeftButton {
  left: 20px;
}

.RightButton {
  right: 20px;
}

@media only screen and (min-width: 768px) {
  .GalleryButton {
    height: 90px;
    width: 90px;
  }
  .LeftButton {
    left: 50px;
  }

  .RightButton {
    right: 50px;
  }
}

.lightboxVideo {
  max-width: 100vw;
}

@media only screen and (min-width: 768px) {
  .lightboxVideo {
    max-width: 68vw;
  }
}

.lightboxImage {
  max-width: 100vw;
}

@media only screen and (min-width: 768px) {
  .lightboxImage {
    max-width: 68vw;
  }
}

#LightboxTitleWrapper {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  padding: 50px;
  padding-top: 0px;
  padding-bottom: 40px;
}

#LightboxTitleContainer {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  width: 100%;
  color: white;
}

#LightboxDescription {
  padding-top: 8px;
  width: 100%;
  color: white;
  font-family: 'Libre Franklin', sans-serif;
  font-size: 14px;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  #LightboxTitleContainer {
    justify-content: center;
  }
}
